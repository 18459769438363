import classNames from 'classnames';
import { i18n } from 'shared/i18n';
import { useFormValid } from 'shared/form';
import 'shared/components/actionsBar/ActionBar.scss';
import './newTemplateActions.scss';

export interface ActionsProps {
  onPublish: () => void;
  onPreview: () => void;
}

const NewTemplateActions = ({ onPublish, onPreview }: ActionsProps) => {
  const isFormValid = useFormValid();
  
  return (
    <div className='new-template-actions'>
      <button
        type="button"
        disabled={!isFormValid}
        title={i18n('actionBar.previewInfo')}
        onClick={onPreview}
        className='actions-bar-button'
      >
        <i className={classNames('icon-eye', 'actions-bar-button-ico')} />
        {i18n('actionBar.newPreview')}
      </button>
      <button
        type="button"
        disabled={!isFormValid}
        className={classNames('actions-bar-button', 'actions-bar-button-validate')}
        onClick={onPublish}
      >
        <i className={classNames('icon-link', 'new-template-actions-ico')} />
        {i18n('newsletters.view.createNewUrl')}
      </button>
    </div>
  );
};

export default NewTemplateActions;
