const ARTICLE = 'article';
const HOME = 'home';
const WORKSHOP = 'workshop';
const EVENT = 'event';
const NEWSLETTER = {
  CLUB: 'newsletter/club',
  DELANOBREAKFAST: 'newsletter/delanobreakfast',
  DELANONOON: 'newsletter/delanonoon',
  MORNING: 'newsletter/morning',
  EVENING: 'newsletter/evening',
  TRENDIN: 'newsletter/trendin',
  GRANDDOSSIER: 'newsletter/granddossier',
};
const DOSSIER = 'dossier';
const PODCASTSHOW = 'podcast-show';

const routes = {
  ARTICLE,
  HOME,
  WORKSHOP,
  EVENT,
  NEWSLETTER,
  DOSSIER,
  PODCASTSHOW,
};

export default routes;
