import { types } from 'shared/constants';
import { NewsletterDetail } from 'views/newsletter/types';
import {
  clubData,
  clubDataCopy,
  clubDataValidate,
  createInitialStateClubData,
  initialStateClubData,
} from 'views/newsletter/state/club';
import {
  GrandDossier,
  GrandDossierInput,
} from 'views/newsletter/categories/GrandDossiersNewsletterForm/types';
import {
  createInitialStateGrandDossier,
  grandDossierData,
  grandDossierDataCopy,
  grandDossierValidate,
  initialStateGrandDossier,
} from 'views/newsletter/state/grandDossier';
import { initialStateTrendin, trendinData, trendinDataCopy, trendinValidate } from 'views/newsletter/state/trendin';
import { Trendin, TrendinInput } from 'views/newsletter/categories/TrendinNewsletterForm/types';
import { NewsletterInput } from '../categories/types';
import { ClubData, ClubInput } from '../categories/ClubNewsletterForm/types';
import {
  delanoBreakfastNoon,
  delanoBreakfastNoonCopy,
  delanoBreakfastNoonValidate,
  initialDelanoBreakfastData,
} from './delanoBreakfastNoon';

export const DELANO_BREAKFAST = 'delanobreakfast';
export const DELANO_NOON = 'delanonoon';
export const GRAND_DOSSIER = 'granddossier';
export const TRENDIN = 'trendin';

export const reformattingValue = (value: Record<string, unknown>, type: string) => {
  switch (type) {
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
    case types.MORNING:
    case types.EVENING:
      return delanoBreakfastNoon(value as NewsletterInput);
    case types.CLUB:
    case types.CLUB_EN:
      return clubData(value as ClubInput);
    case types.GRAND_DOSSIER:
      return grandDossierData(value as GrandDossierInput);
    case types.TRENDIN:
      return trendinData(value as TrendinInput);
  }
  return undefined;
};

export const reformattingCopyValue = (value: Record<string, unknown>, type: string) => {
  switch (type) {
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
    case types.MORNING:
    case types.EVENING:
      return delanoBreakfastNoonCopy(value as NewsletterDetail);
    case types.CLUB:
    case types.CLUB_EN:
      return clubDataCopy(value as ClubData);
    case types.GRAND_DOSSIER:
      return grandDossierDataCopy(value as GrandDossier);
    case types.TRENDIN:
      return trendinDataCopy(value as Trendin);
  }
  return undefined;
};

export const validate = (type: string, value: Record<string, unknown>): Record<string, unknown> => {
  switch (type) {
    case types.DELANO_BREAKFAST:
    case types.DELANO_NOON:
    case types.MORNING:
    case types.EVENING:
      return delanoBreakfastNoonValidate(value as NewsletterInput);
    case types.CLUB:
    case types.CLUB_EN:
      return clubDataValidate(value as ClubInput);
    case types.GRAND_DOSSIER:
      return grandDossierValidate(value as GrandDossierInput);
    case types.TRENDIN:
      return trendinValidate(value as TrendinInput);
    default:
      return {};
  }
};

export const initialStateForModel = (type: string, value: NewsletterDetail, params: string) => {
  if (Object.keys(value.content).length > 0 && params !== 'create') {
    switch (type) {
      case types.CLUB:
      case types.CLUB_EN:
        return initialStateClubData(value);
      case types.TRENDIN:
        return initialStateTrendin(value);
      case types.GRAND_DOSSIER:
        return initialStateGrandDossier(value);
      case types.DELANO_BREAKFAST:
      case types.DELANO_NOON:
      case types.MORNING:
      case types.EVENING:
        return initialDelanoBreakfastData(value);
      default:
        return undefined;
    }
  } else {
    if (type === types.GRAND_DOSSIER) {
      return createInitialStateGrandDossier();
    } else if (type == types.CLUB || type == types.CLUB_EN) {
      return createInitialStateClubData(type);
    }

    return value;
  }
};
