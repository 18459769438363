import { i18n } from 'shared/i18n';
import { PlatformNameService } from 'shared/services';

export const SubsubSectorExpertises: Record<string, string> = {
  'business-strategy': i18n('subSubSector.business-strategy'), // 'Business Strategy',
  'ict-experts-luxembourg': i18n('subSubSector.ict-experts-luxembourg'), // 'ICT Experts Luxembourg',
  'the-intelligent-entreprise': i18n('subSubSector.the-intelligent-entreprise'), // 'The Intelligent Enterprise',
  'working-right': i18n('subSubSector.working-right'), // 'Droit Du Travail',
  'innovation-impulse': i18n('subSubSector.innovation-impulse'), // 'Innovation Impulse',
  'compagny-coaching': i18n('subSubSector.compagny-coaching'), // 'Coaching en Entreprise',
  'corporate-risks': i18n('subSubSector.corporate-risks'), // 'Corporate Risks',
  'marketing-digital': i18n('subSubSector.marketing-digital'), // 'Marketing Digital',
  'event-management': i18n('subSubSector.event-management'), // 'G\u00E9rer son \u00E9v\u00E9nement',
  'ict-gdpr': i18n('subSubSector.ict-gdpr'), // 'Droit: CT, GDPR & Immat\u00E9riel',
  'working-quality': i18n('subSubSector.working-quality'), // 'Qualit\u00E9 de vie au travail',
  'thought-leadership': i18n('subSubSector.thought-leadership'), // 'Thought Leadership',
  'learning-designed': i18n('subSubSector.learning-designed'), // 'Learning Designed for you',
  'luxemburg-insurenc': i18n('subSubSector.luxemburg-insurenc'), // 'Mieux comprendre les assurances au Luxembourg',
  'wealth-planning': i18n('subSubSector.wealth-planning'), // 'Wealth Planning',
  'investissements-etf': i18n('subSubSector.investissements-etf'), // 'Investissements & ETF',
  'cybersecurity-corner': i18n('subSubSector.cybersecurity-corner'), // 'Cybersecurity corner',
  'gestion-fonds': i18n('subSubSector.gestion-fonds'), // 'Gestion de fonds',
  'headhunting-corner': i18n('subSubSector.headhunting-corner'), // 'The headhunting corner',
  'management-participatif': i18n('subSubSector.management-participatif'), // 'Management Participatif',
  'change-management': i18n('subSubSector.change-management'), // 'Change Management',
  'facility-services': i18n('subSubSector.facility-services'), // 'Facility Services',
  'langues-et-tendances': i18n('subSubSector.langues-et-tendances'), // 'Langues & Tendances',
  'data-protection-management': i18n('subSubSector.data-protection-management'), // 'Data protection management',
  'perspectives-marches-financiers': i18n('subSubSector.perspectives-marches'), // 'Perspectives des march\u00E9s financiers',
  'transformation-acc\u00E9l\u00E9ration-performance': i18n(
    'subSubSector.transformation-acc\u00E9l\u00E9ration-performance',
  ),
  'datadriven-tech': i18n('subSubSector.datadriven-tech'), // '#DataDrivenTech',
  'lets-speak-executive-search': i18n('subSubSector.lets-speak-executive-search'), // 'Let\u2019s speak Executive Search',
  'bgl-a-bgl-bnp-paribas': i18n('subSubSector.bgl-a-bgl-bnp-paribas'), // 'De BGL \u00E0 BGL BNP Paribas',
  'hr-business-partner': i18n('subSubSector.hr-business-partner'), // 'HR Business Partner',
  'observatoire-immobilier': i18n('subSubSector.observatoire-immobilier'), // "L'observatoire de l'immobilier ",
  'life-insurance-insights': i18n('subSubSector.life-insurance-insights'), // 'Life insurance insights',
  'chroniques-banque-centenaire': i18n('subSubSector.chroniques-banque-centenaire'), // 'Chroniques d\u2019une banque centenaire',
  'advisory-chronicle': i18n('subSubSector.advisory-chronicle'), // 'Advisory Chronicle',
  'business-legal-insights': i18n('subSubSector.business-legal-insights'), // 'Business & Legal Insights',
  'investir-long-terme': i18n('subSubSector.investir-long-terme'), // 'Investir pour le long terme',
  'arendt-we-live': i18n('subSubSector.arendt-we-live'), // 'Arendt We Live',
  'finance-risk-regulation': i18n('subSubSector.finance-risk-regulation'), // 'Finance, Risk and Regulation',
  'investment-funds-hot-topics': i18n('subSubSector.investment-funds-hot-topics'), // 'Investment Funds Hot Topics',
  'digital-strategy': i18n('subSubSector.digital-strategy'), // 'Digital Strategy',
  'fund-services': i18n('subSubSector.fund-services'), // 'Fund Services',
  'faciliter-linnovation': i18n('subSubSector.faciliter-linnovation'), // "Faciliter l'innovation",
  'imagine-tomorrow': i18n('subSubSector.imagine-tomorrow'), // "Imagine Tomorrow",
  'les-expertises-immobilieres': i18n('subSubSector.les-expertises-immobilieres'), // "Les Expertises Immobilières",
  'digital-voices': i18n('subSubSector.digital-voices'), // "Digital Voices",
  'securities-services-insights': i18n('subSubSector.securities-services-insights'), // "Securities Services Insights",
  'business-talks': i18n('subSubSector.business-talks'), // "Business Talks",
  'mmmediarama': i18n('subSubSector.mmmediarama'), // "MMMediarama"
  'food-for-thought': i18n('subSubSector.food-for-thought'), // "Food for thought"
  'new-comers-corner': i18n('subSubSector.new-comers-corner'), // "New comers corner"
  'legal-viewpoints': i18n('subSubSector.legal-viewpoints'), // "Legal viewpoints"
  'ressources-durables': i18n('subSubSector.ressources-durables') // "Ressources durables"
};

export const SubsubSectorKnowledge: Record<string, string> = {
  'invest-for-the-long-term': i18n('subSubSector.invest-for-the-long-term'), // "Invest for the long term",
  'expat-corner': i18n('subSubSector.expat-corner'), // "Expat Corner",
  'imagine-tomorrow': i18n('subSubSector.imagine-tomorrow'), // "Imagine Tomorrow",
  'mmmediarama': i18n('subSubSector.mmmediarama'), // "MMMediarama",
  'the-finance-nextgen': i18n('subSubSector.the-finance-nextgen'), // "NextGen Finance",
};

export type Subsector = 'Expertises' | 'Knowledge' | string;

export const getSubsubSectors = (subsector: Subsector) => {
  switch (subsector) {
    case 'Expertises':
      return Object.entries(SubsubSectorExpertises).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    case 'Knowledge':
      return Object.entries(SubsubSectorKnowledge).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    default:
      return [];
  }
};

export const getSubsubSectorByName = (subsector: string, subsubSector: string) => {
  const entry = getSubsubSectors(subsector).find((s) => s.label === subsubSector);
  return entry ? entry.label : '';
};

export const getSubSubSectorByKey = (key: string) => {
  if (PlatformNameService.isDelanoPlatform()) {
    return SubsubSectorKnowledge[key];
  } else {
    return SubsubSectorExpertises[key];
  }
};
