import moment from 'moment';
import { setErrors } from 'shared/form/utils';
import { i18n } from 'shared/i18n';
import { appConfig } from 'shared/config/env';
import {
  BillboardsInput,
  GrandDossier,
  GrandDossierInput,
} from 'views/newsletter/categories/GrandDossiersNewsletterForm/types';
import { NewsletterDetail } from 'views/newsletter/types';
import { GrandsDossiers } from 'views/newsletter/constants';
import { computeNewsletterTitle } from 'views/newsletter/state/common';
import { NewsletterGrandDossierContent } from 'shared/types';
import { types } from 'shared/constants';

const blankBillboard = {
  imageFilename: '',
  link: '',
};

export const createInitialStateGrandDossier = () =>  ({
  title: computeNewsletterTitle(types.GRAND_DOSSIER, new Date()),
  publicationDate: new Date(),
  content: {
    slugline: '',
    billboards: [...new Array(GrandsDossiers.BillboardsQuantity)].map(() => ({
      ...blankBillboard,
      billboardsIsOpen: false,
    })),
    magazine: {
      isMagazinePresent: true,
    },
  },
});

export const initialStateGrandDossier = (value: NewsletterDetail) => {
  const content = value.content as NewsletterGrandDossierContent;

  return {
    title: value.title,
    publicationDate: new Date(value.publicationDate),
    content: {
      slugline: value.content.slugline,
      dossier: content.dossier && {
        value: content.dossier.slug,
        label: content.dossier.title,
      },
      billboards: content.billboards.map((billboard) => ({
        ...billboard || blankBillboard,
        billboardsIsOpen: !!billboard,
      })),
      magazine: {
        isMagazinePresent: !!content.magazine,
        ...content.magazine,
      },
    },
  };
};

export const grandDossierData = (value: GrandDossierInput) => ({
  ...value,
  publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
  content: {
    ...value.content,
    dossier: value.content.dossier.value || '',
    billboards: value.content.billboards.map((billboard) => billboard.billboardsIsOpen ? ({
      link: billboard.link,
      imageFilename: billboard.imageFilename,
    }) : null),
    magazine: value.content.magazine.isMagazinePresent
      ? {
        date: value.content.magazine.date,
        description: value.content.magazine.description,
        imageFilename: value.content.magazine.imageFilename,
        link: value.content.magazine.link,
        titre: value.content.magazine.titre,
      }
      : undefined,
  },
});

export const grandDossierDataCopy = (value: GrandDossier) => {
  return ({
    ...value,
    publicationDate: moment(value.publicationDate).format(appConfig.yearBasedDateFormat),
    content: {
      ...value.content,
      dossier: value.content.dossier.slug || '',
    },
  });
};

const isBillboardValid = (billboard: BillboardsInput) => (
  billboard.billboardsIsOpen && !!billboard.imageFilename && !!billboard.link
) || !billboard.billboardsIsOpen;

export const grandDossierValidate = (value: GrandDossierInput) => {
  const errors = {};
  if (value) {
    if (value.content.billboards) {
      if (!value.content.billboards.every((billboard) => isBillboardValid(billboard))){
        setErrors(errors, 'content.billboards', i18n('forms.validation.invalid'));
      }
    } else {
      setErrors(errors, 'content.billboards', i18n('forms.validation.required'));
    }
    if (!value.publicationDate) {
      setErrors(errors, 'publicationDate', i18n('forms.validation.required'));
    }
    if (!value.content.slugline) {
      setErrors(errors, 'content.slugline', i18n('forms.validation.required'));
    }
    if (value.content.magazine && value.content.magazine.isMagazinePresent) {
      if (!value.content.magazine.date) {
        setErrors(errors, 'content.magazine.date', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.description) {
        setErrors(errors, 'content.magazine.description', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.imageFilename) {
        setErrors(errors, 'content.magazine.imageFilename', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.link) {
        setErrors(errors, 'content.magazine.link', i18n('forms.validation.required'));
      }
      if (!value.content.magazine.titre) {
        setErrors(errors, 'content.magazine.titre', i18n('forms.validation.required'));
      }
    } else {
      setErrors(errors, 'content.magazine', i18n('forms.validation.required'));
    }
  }

  return errors;
};
