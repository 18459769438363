import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { i18n } from 'shared/i18n';
import { ArrayInput, DossierSelectInput } from 'shared/form/inputs';
import { ImageUpload } from 'shared/types';
import { GrandsDossiers } from 'views/newsletter/constants';
import BillboardInput from 'views/newsletter/categories/GrandDossiersNewsletterForm/BillboardInput';
import GeneralInput from 'views/newsletter/categories/inputs/GeneralInput';
import { BillboardsInput } from 'views/newsletter/categories/GrandDossiersNewsletterForm/types';
import MagazineInput from './MagazineInput';

export interface  GrandDossiersNewsletterFormProps {
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const GrandDossiersNewsletterForm = ({ onFileSelect, onRemoveFile }: GrandDossiersNewsletterFormProps) => {
  const sectionRefs = useGetSectionRef();

  return (
    <>
      <FormSection
        ref={sectionRefs[GrandsDossiers.GrandsDossiers.id]}
        id={GrandsDossiers.GrandsDossiers.id}
        title={GrandsDossiers.GrandsDossiers.label}
      >
        <GeneralInput
          type="granddossier"
          labelTitle="labels.title"
          labelSlugLine="newsletters.view.textPreview"
        />
        <DossierSelectInput
          required
          name="content.dossier"
          label={i18n('newsletters.view.grandDossier')}
        />
      </FormSection>
      <ArrayInput<BillboardsInput> staticArray name="content.billboards">
        <BillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} />
      </ArrayInput>
      <MagazineInput
        ref={sectionRefs[GrandsDossiers.Magazine.id]}
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};

export default GrandDossiersNewsletterForm;
