import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Content } from 'layout/content';
import { Modal, ActionsBar } from 'shared/components';
import { appConfig, isDelanoPlatform } from 'shared/config/env';
import { i18n } from 'shared/i18n';
import { Form } from 'shared/form';
import GetUrlModal from 'views/newsletter/modal/GetUrlModal';
import CategorizationForm from 'views/newsletter/categories';
import NewTemplateActions from './components/NewTemplateActions';
import { initialStateForModel } from './state';
import HeaderNewsletter from './HeaderNewsletter';
import './newsletterDetail.scss';
import useInteractionNewsletterDetailView from './useInteractionNewsletterDetailView';

const NewsletterDetailView = ({ match }: RouteComponentProps<{ type: string; id: string }>) => {
  const {
    onSubmit,
    validation,
    newsletterDetail,
    type,
    openPreview,
    closeModal,
    modalOpen,
    onFileSelect,
    onRemoveFile,
    newsletterUrl,
    isNewNewsletterEndpoint,
    publish,
    publishNewNewsletterUrl,
    previewNewNewsletter,
    loading,
  } = useInteractionNewsletterDetailView(match);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validation}
      initialValues={initialStateForModel(type, newsletterDetail, match.params.id)}
    >
      <HeaderNewsletter
        initialPublicationDate={newsletterDetail.publicationDate}
        initialTitle={newsletterDetail.title}
        isCreating={match.params.id === 'create'}
      />
      <Content loading={loading}>
        <div className="newsletter-detail-content">{CategorizationForm(type, onFileSelect, onRemoveFile)}</div>
        <ActionsBar
          canUpdate
          canPreview
          canPublish
          preview={openPreview}
          publish={publish}
          customPublishLabel={i18n('newsletters.view.createUrl')}
          customPublishIcon="icon-link"
        />
        {appConfig.newsletterEndpointEnable && (
          <NewTemplateActions onPreview={previewNewNewsletter} onPublish={publishNewNewsletterUrl} />
        )}
        <Modal onModalClose={closeModal} isOpen={modalOpen}>
          <GetUrlModal
            newsletterUrl={newsletterUrl}
            platform={`${isDelanoPlatform && !isNewNewsletterEndpoint ? '?platformName=delano' : ''}`}
            newsletterTitle={i18n('newsletters.title')}
            onModalClose={closeModal}
            newNewsletterEndpoint={isNewNewsletterEndpoint}
          />
        </Modal>
      </Content>
    </Form>
  );
};

export default withRouter(NewsletterDetailView);
