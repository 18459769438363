import { BaseAdsContent, BaseMagazine } from 'views/newsletter/categories/types';
import { SluglineExpertises } from 'views/newsletter/types';

export interface SelectOption extends Record<string, unknown> {
  value: string;
  label: string;
}

export interface SlugAndTitle extends Record<string, unknown> {
  slug: string;
  title: string;
}

export interface Pagination {
  lastPage: number;
  nbDisplayedElements?: number;
  nbElementsPerPage?: number;
  page: number;
  total?: number;
  url?: string;
}

export interface SelectLinkedContent {
  articles: SelectOption[];
  events: SelectOption[];
  sponsoredContent: SelectOption[];
}

export interface Publication {
  date: string | Date;
  isPublished: boolean;
  target?: PublicationTarget;
  newsTicker?: boolean;
}

export interface PublicationTarget {
  news: boolean;
  club: boolean;
}

export interface LinkedContent {
  articles: SlugAndTitle[];
  events: SlugAndTitle[];
  sponsoredContent: Partial<SlugAndTitle>[];
}

export interface MainImage {
  filename: string;
  caption?: string;
  credits?: string;
  _type?: string;
  focusPoint?: FocusPoint;
}

export interface BreakingNews {
  isActive?: boolean;
  slug: string;
  title: string;
}

export interface FocusPoint {
  x: number;
  y: number;
}

export interface Content {
  content: ContentBody[];
  _type: string;
}

export interface ContentBody {
  value: string | ContentBody;
  _type: string;
  tag?: string;
  name?: string;
  type?: string;
}

export interface BasePodcastShow extends Record<string, unknown> {
  creationDate: string | Date;
  isSponsored: boolean;
  mainImage: MainImage;
  platformName?: string;
  publication: Publication;
  slug: string;
  subscribeUrl: string;
  title: string;
}

export interface ListModel<T> {
  list: T[];
  isLoading: boolean;
  perPage: number;
  sortPredicate: string;
  pagination?: Pagination;
  isCreateModalOpen?: boolean;
}

export interface Sponsors {
  sponsorsGold: string[];
  sponsorsSilver: string[];
}

export interface SelectSponsors {
  sponsorsGold: SelectOption[];
  sponsorsSilver: SelectOption[];
}

export interface Location {
  city: string;
  country: string;
  name: string;
  street: string;
  zipCode: string;
}

export interface PracticalInfo {
  beginDate: string | Date;
  endDate?: string | Date;
  languages?: string[];
  location: Location;
}

export interface FormSection {
  id?: string;
  title?: string;
}

export interface Error {
  response: Response;
}

export interface Response {
  data: DataError;
}

export interface DataError {
  status: number;
  title: string;
  source: string;
  detail: string;
  otherErrors: unknown[];
}

export interface QueryParams {
  filter?: string;
  q?: string;
  platformName?: string;
}

export interface Params {
  titleQuery?: string;
  page: number;
  nbElementPerPage: number;
  sort: string;
  perPage?: number;
  sortPredicate?: string;
  isLoading?: boolean;
}

export interface ResponseListBody<T> extends Record<string, unknown> {
  items: T[];
  pagination?: Pagination;
  count?: number;
  limit?: number;
  offset?: number;
}

export interface EventLinkedContent {
  articles: string[];
  events: string[];
  sponsoredContent: string[];
}

export interface Topic extends Record<string, unknown> {
  name: string;
}

export interface Slug extends Record<string, unknown> {
  slug: string;
}

export type Role = 'News' | 'Sales' | 'Agence' | 'Club' | 'Guide' | 'IT_Digital';

export interface UserSession {
  _id: string;
  createdAt: string;
  email: string;
  expireAt: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  roleId: Role;
  roleName: Role;
  sessionKind: string;
  userReference: string;
  username: string;
}

export interface SlugParams {
  id: string;
}

export type Refetch = (params: QueryParams) => Promise<SlugAndTitle[]>;

export const Kind: Record<string, string> = {
  delanonoon: 'DelanoNoon',
  delanobreakfast: 'DelanoBreakfast',
  morning: 'Morning',
  evening: 'Evening',
  club: 'Club',
  trendin: 'Trendin',
  granddossier: 'GrandDossier',
};

export interface BaseNewsletterTrendinContent extends SluglineExpertises {
  decodeurs: string;
  troisQuestionsA: string;
  focus: string;
  expertises: string[];
  laMinuteLuxInnovation: string;
  lInstantT: string;
  enjeux: string[];
  startuppers: string[];
  brandVoice: string;
  enBref: string[];
  agenda: string[];
  laPresseEnParle: string[];
}

export interface NewsletterTrendinContent extends BaseNewsletterTrendinContent {
  adsBillboard1: BaseAdsContent;
  adsBillboard2: BaseAdsContent;
  adsBillboard3: BaseAdsContent;
}

export interface NewsletterClubContent extends SluglineExpertises {
  aLaUne: SlugAndTitle[];
  prochainement: SlugAndTitle[];
}

export interface Magazine extends BaseMagazine {
  titre: string;
}

export interface NewsletterGrandDossierContent extends SluglineExpertises {
  dossier: SlugAndTitle;
  billboards: BaseAdsContent[];
  magazine: Magazine;
}

export interface Image {
  fileName: string;
  base64: string;
}

export interface ImageUpload {
  [fieldName: string]: Image;
}
