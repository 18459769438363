import { ReactNode, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';
import Routing from 'shared/stores/routeStore';
import { Aside } from 'layout/aside/Aside';
import 'layout/Layout.scss';
import ScrollspyProvider from './ScrollspyProvider';
import UserSessionContext from 'shared/stores/user/store';

export interface LayoutProps extends RouteComponentProps {
  children: ReactNode;
}

const Layout = ({ history, children }: LayoutProps) => {
  const userSession = useContext(UserSessionContext);
  
  return (
    <div className='root'>
    <Routing.Consumer>
      {(routeStore) => {
          routeStore.init(history as unknown as History);
          routeStore.storeRoute(history.location as Location);
        return (
          <ScrollspyProvider routeStore={routeStore}>
            {userSession && !!userSession.roleName && <Aside routeStore={routeStore} />}
            <main className='main'>{children}</main>
          </ScrollspyProvider>
        );
      }}
    </Routing.Consumer>
  </div>
); };

export default withRouter(Layout);
