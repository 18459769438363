import { UserSession } from 'shared/types';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const toUserSessionDto = async (response: CognitoUser): Promise<UserSession> => {
  const cognitoUserSession: CognitoUserSession | null = response.getSignInUserSession();

  const signInUserSession = cognitoUserSession?.getIdToken().payload ?? {};

  const userSession: UserSession = {
    _id: signInUserSession.jti || '',
    createdAt: signInUserSession.auth_time || 0,
    email: signInUserSession.email || '',
    expireAt: signInUserSession.exp || 0,
    firstName: signInUserSession.name || '',
    lastName: signInUserSession.family_name || '',
    profilePictureUrl: '',
    roleId: signInUserSession['cognito:groups']?.[0] || '',
    roleName: signInUserSession['cognito:groups']?.[1] || '',
    sessionKind: '',
    userReference: signInUserSession.event_id || '',
    username: signInUserSession.preferred_username || '',
  };

  return Promise.resolve(userSession);
};
