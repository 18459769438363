import { FailliteStatus } from 'shared/models';
import { i18n } from 'shared/i18n';
import { appConfig } from 'shared/config/env';
import AdInput from 'shared/form/inputs/AdInput';
import {
  ArrayInput,
  BiographySelectInput,
  DnDArrayInput,
  EventSelectInput,
  SimpleInput,
  SlugInput,
  ToggleInput,
} from 'shared/form/inputs';
import { ArticleSelect } from 'shared/components';
import { ImageUpload, SelectOption } from 'shared/types';
import { resources } from 'shared/constants';
import { FormSection } from 'layout/content';
import useGetSectionRef from 'layout/ScrollspyProvider/useGetSectionRef';
import { MorningEvening } from 'views/newsletter/constants';
import GeneralInput from '../inputs/GeneralInput';
import SlugAndTitleInput from '../inputs/SlugAndTitleInput';
import ExternalApiDefinitionSection from '../inputs/ExternalApiDefinitionSection';
import AdsBillboardInput from '../inputs/AdsBillboardInput';
import FaillitesItemInput from './FaillitesItemInput';
import JobsSectionInput from '../daily/JobsSectionInput';
import PhotoDuJour from '../inputs/PhotoDuJour';
import ChiffreDuJour from '../inputs/ChiffreDuJour';
import CitationDuJour from '../inputs/CitationDuJour';
import ClubMemberNewsInput from '../inputs/ClubMemberNewsInput';
import EnCeMomentDansLeMagazineInput from '../inputs/EnCeMomentDansLeMagazineInput';
import ParusChezMaisonModerneInput from '../inputs/ParusChezMaisonModerneInput';
import TweetDuJourInput from '../inputs/TweetDuJourInput';
import { FaillitesInput, ParusChezMaisonModerne } from '../types';

export interface MorningEveningNewsletterFormProps {
  type: string
  onFileSelect: (image: ImageUpload) => void;
  onRemoveFile: (name: string) => void;
}

const MorningEveningNewsletterForm = ({ type, onFileSelect, onRemoveFile }: MorningEveningNewsletterFormProps) => {
  const sectionRefs = useGetSectionRef();
  const getEmptyValue = () => {
    return {
      nomSociete: '',
      typeSociete: '',
      status: {
        value: FailliteStatus.Faillite,
        label: FailliteStatus.Faillite,
      },
    };
  };
  return (
    <>
      <FormSection
        ref={sectionRefs[MorningEvening.General.id]}
        id={MorningEvening.General.id}
        title={MorningEvening.General.label}
      >
        <GeneralInput
          type={type}
          labelTitle="newsletters.view.referenceNewsletter"
          labelSlugLine="newsletters.view.preHeaderMail"
        />
      </FormSection>

      <SlugAndTitleInput
        ref={sectionRefs[MorningEvening.BreakingNews.id]}
        id={MorningEvening.BreakingNews.id}
        title={MorningEvening.BreakingNews.label}
        name="content.breakingNews"
        toggleId="toggler-breakingnews"
      />

      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard1.id]}
        id={MorningEvening.AdsBillboard1.id}
        title={MorningEvening.AdsBillboard1.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={1} />
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.ASuivre.id]}
        id={MorningEvening.ASuivre.id}
        title={MorningEvening.ASuivre.label}
      >
        <ArrayInput<string> required name="content.aSuivre" label="newsletters.view.toBeContinued">
          <SimpleInput width="100%" />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.ALaUne.id]}
        id={MorningEvening.ALaUne.id}
        title={MorningEvening.ALaUne.label}
      >
        <SlugInput
          required
          label={i18n('labels.article')}
          name="content.aLaUne"
          resource={resources.ARTICLES}
          options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
        >
          <ArticleSelect />
        </SlugInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsImu.id]}
        id={MorningEvening.AdsImu.id}
        title={MorningEvening.AdsImu.label}
      >
        <AdInput
          onRemoveFile={onRemoveFile}
          onFileSelect={onFileSelect}
          togglerId="adsimu-id"
          nameLink="content.adsImu.link"
          toggleInputName="content.adsImu.isActive"
          filename="content.adsImu.imageFilename"
          uploadUrl="/api/newsletter/image"
        />
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.PolitiqueInstitutions.id]}
        id={MorningEvening.PolitiqueInstitutions.id}
        title={MorningEvening.PolitiqueInstitutions.label}
      >
        <DnDArrayInput<string> name="content.sectors.politiqueInstitutions" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:politiqueinstitutions" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard2.id]}
        id={MorningEvening.AdsBillboard2.id}
        title={MorningEvening.AdsBillboard2.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={2} />
      </FormSection>
      <ExternalApiDefinitionSection
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.MyLifeLu.id]}
        toggleId="toggler-mylifelu"
        id={MorningEvening.MyLifeLu.id}
        title={MorningEvening.MyLifeLu.label}
        name="myLifeLu"
      />
      <FormSection
        ref={sectionRefs[MorningEvening.EntreprisesStratégies.id]}
        id={MorningEvening.EntreprisesStratégies.id}
        title={MorningEvening.EntreprisesStratégies.label}
      >
        <DnDArrayInput<string> name="content.sectors.entreprisesEtStrategies" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:entreprisesstrategies" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard3.id]}
        id={MorningEvening.AdsBillboard3.id}
        title={MorningEvening.AdsBillboard3.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={3} />
      </FormSection>
      <ExternalApiDefinitionSection
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.Enjoy.id]}
        toggleId="toggler-enjoy"
        name="enjoy"
        id={MorningEvening.Enjoy.id}
        title={MorningEvening.Enjoy.label}
      />
      <FormSection
        ref={sectionRefs[MorningEvening.InspiringWorkplace.id]}
        id={MorningEvening.InspiringWorkplace.id}
        title={MorningEvening.InspiringWorkplace.label}
      >
        <ToggleInput id="toggler-inspiringWorkplace" name="content.inspiringWorkplace.isActive">
          <SlugInput
            required
            label={i18n('labels.article')}
            name="content.inspiringWorkplace.slug"
            resource={resources.ARTICLES}
            options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          >
            <ArticleSelect filter="subsubSector:inspiring-workplace" />
          </SlugInput>
        </ToggleInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.ObservatoireImmobilier.id]}
        id={MorningEvening.ObservatoireImmobilier.id}
        title={MorningEvening.ObservatoireImmobilier.label}
      >
        <ToggleInput id="toggler-observatoireImmobilier" name="content.observatoireImmobilier.isActive">
          <SlugInput
            required
            label={i18n('labels.article')}
            name="content.observatoireImmobilier.slug"
            resource={resources.ARTICLES}
            options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          >
            <ArticleSelect filter="subsubSector:observatoire-immobilier" />
          </SlugInput>
        </ToggleInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.PlaceFinancière.id]}
        id={MorningEvening.PlaceFinancière.id}
        title={MorningEvening.PlaceFinancière.label}
      >
        <DnDArrayInput<string> name="content.sectors.placeFinanciereEtMarches" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:placefinancieremarches" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard4.id]}
        id={MorningEvening.AdsBillboard4.id}
        title={MorningEvening.AdsBillboard4.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={4} />
      </FormSection>
      <ExternalApiDefinitionSection
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.Delano.id]}
        toggleId="toggler-delano"
        id={MorningEvening.Delano.id}
        title={MorningEvening.Delano.label}
        name="businessInEnglish"
      />
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard5.id]}
        id={MorningEvening.AdsBillboard5.id}
        title={MorningEvening.AdsBillboard5.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={5} />
      </FormSection>
      <PhotoDuJour
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.PhotoDuJour.id]}
        id={MorningEvening.PhotoDuJour.id}
        title={MorningEvening.PhotoDuJour.label}
      />
      <TweetDuJourInput
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.TweetDuJour.id]}
        id={MorningEvening.TweetDuJour.id}
        title={MorningEvening.TweetDuJour.label}
      />
      <ChiffreDuJour
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.ChiffreDuJour.id]}
        id={MorningEvening.ChiffreDuJour.id}
        title={MorningEvening.ChiffreDuJour.label}
      />
      <CitationDuJour
        ref={sectionRefs[MorningEvening.CitationDuJour.id]}
        id={MorningEvening.CitationDuJour.id}
        title={MorningEvening.CitationDuJour.label}
      />
      {appConfig.platformName === 'delano' ? (
        <FormSection
          ref={sectionRefs[MorningEvening.Knowledge.id]}
          id={MorningEvening.Knowledge.id}
          title={MorningEvening.Knowledge.label}
        >
          <ArrayInput<string> name="content.expertises" label="labels.article">
            <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
              <ArticleSelect filter="subSector:knowledge" />
            </SlugInput>
          </ArrayInput>
        </FormSection>
      ) : (
        <FormSection
          ref={sectionRefs[MorningEvening.Expertises.id]}
          id={MorningEvening.Expertises.id}
          title={MorningEvening.Expertises.label}
        >
          <ArrayInput<string> name="content.expertises" label="labels.article">
            <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
              <ArticleSelect filter="subSector:expertises" />
            </SlugInput>
          </ArrayInput>
        </FormSection>
      )}
      <FormSection
        ref={sectionRefs[MorningEvening.InvestLongTerm.id]}
        id={MorningEvening.InvestLongTerm.id}
        title={MorningEvening.InvestLongTerm.label}
      >
        <ToggleInput id="toggler-investLongTerm" name="content.externalApi.investLongTerm.isActive">
          <SlugInput
            required
            name="content.externalApi.investLongTerm.slug"
            resource={resources.ARTICLES}
            label={i18n('labels.article')}
            options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          >
            <ArticleSelect />
          </SlugInput>
        </ToggleInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard6.id]}
        id={MorningEvening.AdsBillboard6.id}
        title={MorningEvening.AdsBillboard6.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={6} />
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.LifestyleViePratique.id]}
        id={MorningEvening.LifestyleViePratique.id}
        title={MorningEvening.LifestyleViePratique.label}
      >
        <DnDArrayInput<string> name="content.sectors.lifestyleEtViePrivee" label="labels.article">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="sector:lifestyleviepratique" />
          </SlugInput>
        </DnDArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.AdsBillboard7.id]}
        id={MorningEvening.AdsBillboard7.id}
        title={MorningEvening.AdsBillboard7.label}
      >
        <AdsBillboardInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} index={7} />
      </FormSection>
      <ExternalApiDefinitionSection
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.Explorator.id]}
        toggleId="toggler-explorator"
        id={MorningEvening.Explorator.id}
        title={MorningEvening.Explorator.label}
        name="vousMangezOuCeMidi"
      />
      <FormSection
        ref={sectionRefs[MorningEvening.Carrieres.id]}
        id={MorningEvening.Carrieres.id}
        title={MorningEvening.Carrieres.label}
      >
        <ArrayInput<string> name="content.carrieres" label="newsletters.view.career">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect filter="subSector:mouvements" />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.Faillites.id]}
        id={MorningEvening.Faillites.id}
        title={MorningEvening.Faillites.label}
      >
        <ArrayInput<FaillitesInput>
          name="content.faillites"
          getEmptyValue={getEmptyValue}
        >
          <FaillitesItemInput />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.Anniversaires.id]}
        id={MorningEvening.Anniversaires.id}
        title={MorningEvening.Anniversaires.label}
      >
        <ArrayInput<SelectOption> name="content.anniversaires" label="newsletters.view.anniversary">
          <BiographySelectInput />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.BonnesNouvelles.id]}
        id={MorningEvening.BonnesNouvelles.id}
        title={MorningEvening.BonnesNouvelles.label}
      >
        <ArrayInput<string> name="content.topBonnesNouvelles" label="newsletters.view.goodNews">
          <SlugInput resource={resources.ARTICLES} options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}>
            <ArticleSelect />
          </SlugInput>
        </ArrayInput>
      </FormSection>
      <JobsSectionInput
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.Jobs.id]}
        id={MorningEvening.Jobs.id}
        label={MorningEvening.Jobs.label}
      />
      <FormSection
        ref={sectionRefs[MorningEvening.Agenda.id]}
        id={MorningEvening.Agenda.id}
        title={MorningEvening.Agenda.label}
      >
        <ArrayInput<SelectOption> name="content.clubPart.agenda" label="labels.event">
          <EventSelectInput withWorkshop />
        </ArrayInput>
      </FormSection>
      <FormSection
        ref={sectionRefs[MorningEvening.Club.id]}
        id={MorningEvening.Club.id}
        title={MorningEvening.Club.label}
      >
        <ToggleInput id="toggler-Club" name="content.clubPart.paperjamClub.isActive">
          <SimpleInput
            required
            width="100%"
            label={i18n('labels.title')}
            name="content.clubPart.paperjamClub.title"
          />
          <SlugInput
            required
            name="content.clubPart.paperjamClub.slug"
            label={i18n('labels.article')}
            resource={resources.ARTICLES}
            options={{ additionalResource: resources.ARTICLE_WITH_TITLES }}
          >
            <ArticleSelect />
          </SlugInput>
        </ToggleInput>
      </FormSection>
      <ClubMemberNewsInput
        ref={sectionRefs[MorningEvening.ClubMembers.id]}
        id={MorningEvening.ClubMembers.id}
        title={MorningEvening.ClubMembers.label}
      />
      <EnCeMomentDansLeMagazineInput
        onRemoveFile={onRemoveFile}
        onFileSelect={onFileSelect}
        ref={sectionRefs[MorningEvening.Magazine.id]}
        id={MorningEvening.Magazine.id}
        title={MorningEvening.Magazine.label}
      />
      <FormSection
        ref={sectionRefs[MorningEvening.ParusChez.id]}
        id={MorningEvening.ParusChez.id}
        title={MorningEvening.ParusChez.label}
      >
        <ArrayInput<ParusChezMaisonModerne> name="content.parusChezMaisonModerne">
          <ParusChezMaisonModerneInput onRemoveFile={onRemoveFile} onFileSelect={onFileSelect} />
        </ArrayInput>
      </FormSection>
    </>
  );
};

export default MorningEveningNewsletterForm;
