import moment from 'moment';
import {
  extractValuesFrom,
  fromSlugTitleToSelectOptions,
  onlyNonEmptyItemsFrom,
} from 'shared/utils/utils';
import { SelectOption } from 'shared/types';
import {
  getEventFormatAsSelectOptionBy,
  getEventThemeAsSelectOptionBy,
  createSelectOption,
} from 'shared/models';
import { EventDetail, InputEvent } from 'views/event/types';

export const eventFormModel: InputEvent = ({
  slug: '',
  metadata: {
    title: '',
    format: { value: '', label: '' },
    theme: { value: '', label: '' },
    tags: [],
    linkedContent: {
      articles: [{ value: '', label: '' }],
      sponsoredContent: [],
      events: [{ value: '', label: '' }],
    },
  },
  content: {
    speakers: [{ value: '', label: '' }],
    sponsors: {
      sponsorsGold: [{ value: '', label: '' }],
      sponsorsSilver: [{ value: '', label: '' }],
    },
    video: { value: '', label: '' },
    photo: { value: '', label: '' },
  },
  publication: {
    date: new Date(),
    isPublished: true,
    target: {
      news: true,
      club: false,
    },
  },
  eventzillaEventId: '',
});

export const toEventFormModel = (event: EventDetail): InputEvent => ({
  slug: event.slug,
  metadata: {
    title: event.metadata.title,
    format: getEventFormatAsSelectOptionBy(event.metadata.format) || {
      value: '',
      label: '',
    },
    theme: getEventThemeAsSelectOptionBy(event.metadata.theme) || {
      value: '',
      label: '',
    },
    tags: event.metadata.tags.map((tag: string) => createSelectOption(tag, tag)),
    linkedContent: {
      articles: fromSlugTitleToSelectOptions(event.metadata.linkedContent.articles),
      sponsoredContent: [],
      events: fromSlugTitleToSelectOptions(event.metadata.linkedContent.events),
    },
  },
  content: {
    speakers: fromSlugTitleToSelectOptions(event.content.speakers),
    sponsors: {
      sponsorsGold: fromSlugTitleToSelectOptions(event.content.sponsors.sponsorsGold),
      sponsorsSilver: fromSlugTitleToSelectOptions(event.content.sponsors.sponsorsSilver),
    },
    video: event.content.videos && fromSlugTitleToSelectOptions([event.content.videos])[0],
    photo: event.content.photos && fromSlugTitleToSelectOptions([event.content.photos])[0],
  },
  publication: {
    date: event.publication.date
      ? moment(event.publication.date).toDate()
      : moment().toDate(),
    isPublished: event.publication.isPublished,
    target:  event.publication.target && {
      news: event.publication.target.news,
      club: event.publication.target.club,
    },
  },
  eventzillaEventId: (event.eventzillaEventId && `${event.eventzillaEventId}`) || '',
});

export const toEventDto = (inputEvent: InputEvent) => ({
  metadata: {
    format: inputEvent.metadata.format.value,
    theme: inputEvent.metadata.theme.value,
    updateDate: new Date(),
    tags: inputEvent.metadata.tags.map((tag: SelectOption) => tag.value),
    linkedContent: {
      articles: extractValuesFrom(onlyNonEmptyItemsFrom(inputEvent.metadata.linkedContent.articles)),
      sponsoredContent: [],
      events: extractValuesFrom(onlyNonEmptyItemsFrom(inputEvent.metadata.linkedContent.events)),
    },
  },
  content: {
    speakers: extractValuesFrom(onlyNonEmptyItemsFrom(inputEvent.content.speakers)),
    sponsors: {
      sponsorsGold: extractValuesFrom(onlyNonEmptyItemsFrom(inputEvent.content.sponsors.sponsorsGold)),
      sponsorsSilver: extractValuesFrom(onlyNonEmptyItemsFrom(inputEvent.content.sponsors.sponsorsSilver)),
    },
    video: (inputEvent.content.video && inputEvent.content.video.value) || null,
    photo: (inputEvent.content.photo && inputEvent.content.photo.value) || null,
  },
  publication: {
    date: inputEvent.publication.date,
    isPublished: inputEvent.publication.isPublished,
    target: inputEvent.publication.target && {
      news: inputEvent.publication.target.news,
      club: inputEvent.publication.target.club,
    },
  },
  eventzillaEventId: parseInt(inputEvent.eventzillaEventId) || undefined,
});
