import { ReactNode } from 'react';
import classNames from 'classnames';
import Routing from 'shared/stores/routeStore';
import UserStore from 'shared/stores/user/store';
import { useAuthorized, useLogout } from 'shared/providers';
import './header.scss';

export interface HeaderProps {
  children: ReactNode;
}

const Header = ({ children }: HeaderProps) => {
  const { user } = useAuthorized();
  const logout = useLogout();

  return (
    <Routing.Consumer>
      {({ isItemRoute }) => (
        <header className='header'>
          <div className={'header__content'}>{children}</div>
          {!isItemRoute ? (
            <div className={'header__user'}>
              <i className={classNames('icon-user', 'header__user-image')} />
              <UserStore.Consumer>
                {(session) => {
                  if (session) {
                    const { firstName, lastName, roleName } = session;
                    return (
                      <div className={'header__user-text'}>
                        <span className={'header__user-name'}>
                          {firstName} {lastName}
                        </span>
                        <span className={'header__user-role'}>{roleName}</span>
                      </div>
                    );
                  }
                  return null;
                }}
              </UserStore.Consumer>
              {user && (
                <button type='button' className={'header__user-logout'} onClick={logout}>
                  Logout
                </button>
              )}
            </div>
          ) : null}
        </header>
      )}
    </Routing.Consumer>
  );
};

export default Header;
