import { useContext, useMemo } from 'react';
import UserSessionContext, { ROLE_IT_DIGITAL } from 'shared/stores/user/store';
import { Sections, SubSection } from 'views/Views';

const useNavMenuItems = () => {
  const userSession = useContext(UserSessionContext);
  const navItems: SubSection[] = Object.values(Sections);
  const filteredNavMenuItemsByRole = useMemo(() => {
    if (userSession) {
      return navItems.filter((navItem) =>
        userSession.roleName === ROLE_IT_DIGITAL || navItem.roles.indexOf(userSession.roleId) > -1
      );
    }
    return [];
  }, [userSession, navItems]);

  return filteredNavMenuItemsByRole;
};

export default useNavMenuItems;
